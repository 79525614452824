import React from "react";

export default function MenuIcon({ className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 53 13"
      className={className}
      aria-hidden="true"
    >
      <g
        stroke="currentColor"
        strokeWidth="3"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="square"
      >
        <path d="M1.5 11.5h50M11.5 1.5h40" />
      </g>
    </svg>
  );
}
