export const main = {
  out: {},
  enter: {
    transition: {
      delayChildren: 0.3,
      staggerChildren: 0.1,
    },
  },
  exit: {},
};

export const parent = {
  out: {},
  enter: {
    transition: {
      staggerChildren: 0.1,
    },
  },
  exit: {},
};

export const fadeIn = {
  out: { opacity: 0 },
  enter: {
    opacity: 1,
    transition: { duration: 0.5 },
  },
  exit: { opacity: 0, transition: { duration: 0.5 } },
};

export const fadeUp = {
  out: {
    translateY: 50,
    opacity: 0,
  },
  enter: {
    opacity: 1,
    translateY: 0,
    transition: {
      duration: 0.5,
    },
  },
  exit: {
    translateY: 50,
    opacity: 0,
    transition: {
      duration: 0.5,
    },
  },
};

export const fadeRight = {
  out: {
    translateX: -100,
    opacity: 0,
  },
  enter: {
    translateX: 0,
    opacity: 1,
    transition: {
      duration: 0.5,
    },
  },
  exit: {
    translateX: -100,
    opacity: 0,
    transition: {
      duration: 0.5,
    },
  },
};

export const fadeLeft = {
  out: {
    translateX: 100,
    opacity: 0,
  },
  enter: {
    translateX: 0,
    opacity: 1,
    transition: {
      duration: 0.5,
    },
  },
  exit: {
    translateX: 100,
    opacity: 0,
    transition: {
      duration: 0.5,
    },
  },
};

export const fadeZoom = {
  out: {
    scale: 0.75,
    opacity: 0,
  },
  enter: {
    scale: 1,
    opacity: 1,
    transition: {
      duration: 0.5,
    },
  },
  exit: {
    scale: 0.75,
    opacity: 0,
    transition: {
      duration: 0.5,
    },
  },
};

export const slideOut = {
  out: {
    translateX: "-100%",
  },
  enter: {
    translateX: "0%",
    transition: {
      duration: 0.5,
    },
  },
  exit: {
    translateX: "-100%",
    transition: {
      duration: 0.5,
    },
  },
};
