import React from "react";

export default function ArrowUpRightIcon({ className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
      className={className}
      aria-hidden="true"
    >
      <path
        d="M1.359 0v2.662h10.096L0 14.118 1.882 16 13.338 4.544v10.097H16V0z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
}
