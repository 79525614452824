import React, { useEffect, useRef, useState } from "react";

export default function InlineWordsSlider({
  words,
  autoplay = true,
  duration = 5000,
  delay = null,
}) {
  const ref = useRef();
  const [active, setActive] = useState(0);
  const [delayValue, setDelayValue] = useState(delay);
  const { length } = words;
  const max = length - 1;

  const elements = words.map((word, index) => {
    return {
      offset: ((max - index) * -100) / length,
      word,
    };
  });

  useEffect(() => {
    if (autoplay) {
      const timeout = setTimeout(() => {
        setActive((a) => (a === max ? 0 : a + 1));
      }, delayValue + duration);

      setDelayValue(0);

      return () => {
        clearTimeout(timeout);
      };
    }

    return null;
  }, [active]);

  return (
    <strong className="relative text-dark max-h-[4.5rem] 2xl:max-h-[5.5rem] overflow-hidden">
      <span
        className="absolute inset-0 bg-primary transition-all ease-in-sine duration-500"
        style={{
          maxWidth: `${
            ref.current && ref.current.children
              ? Array.from(ref.current.children)[max - active].firstElementChild
                  .clientWidth
              : 0
          }px`,
        }}
        aria-hidden="true"
      />
      <span
        ref={ref}
        className="flex flex-col transition-transform ease-in-sine duration-500 will-change-transform"
        style={{
          transform: `translateY(${elements[active].offset}%)`,
        }}
      >
        {elements.map((element, index) => {
          const isActive = index === active;

          return (
            <span key={element.word}>
              <span
                ref={ref}
                className="inline-block py-4 px-5 whitespace-nowrap"
                aria-hidden={!isActive}
              >
                {element.word}
              </span>
            </span>
          );
        })}
      </span>
    </strong>
  );
}
