import React from "react";
import Button from "components/Button";
import BrandingLogo from "components/SVG/BrandingLogo";
import SigilIllustration from "components/SVG/Illustration/SigilIllustration";
import InlineWordsSlider from "components/InlineWordsSlider";
import Link from "next/link";
import { m } from "framer-motion";
import { fadeRight, fadeZoom, parent } from "lib/variants";

export default function HomeSection({ links }) {
  return (
    <section className="xl:h-screen bg-dark text-light">
      <div className="relative container px-4 lg:px-12 py-12 mx-auto flex flex-col h-full">
        <div className="hidden lg:block flex-initial">
          <BrandingLogo className="w-[200px]" showBaseline />
          <h1 className="sr-only">
            ISICS, agence de développement web et mobile depuis 2004
          </h1>
        </div>
        <div className="flex-1 flex flex-col gap-12">
          <div className="flex-1 flex flex-col">
            <m.div
              variants={parent}
              initial="out"
              whileInView="enter"
              className="flex flex-col justify-center gap-6 font-important tracking-wider text-4xl xl:text-5xl 2xl:text-6xl font-semibold h-full will-change-transform"
            >
              <m.span
                variants={fadeRight}
                className="flex flex-wrap items-center gap-4 will-change-transform"
              >
                <span>L&apos;agence web</span>{" "}
                <InlineWordsSlider
                  words={["ardennaise", "créative", "d’experts"]}
                />
              </m.span>
              <m.span
                variants={fadeRight}
                className="flex flex-wrap items-center gap-4 will-change-transform"
              >
                <span>qui</span>{" "}
                <InlineWordsSlider
                  words={["donne vie à", "développe", "propulse"]}
                  delay={200}
                />
              </m.span>
              <m.span
                variants={fadeRight}
                className="flex flex-wrap items-center gap-4 will-change-transform"
              >
                <InlineWordsSlider
                  words={["vos projets", "votre visibilité", "vos idées"]}
                  delay={400}
                />
              </m.span>
            </m.div>
            <div className="hidden xl:block">
              <SigilIllustration className="absolute top-1/2 right-[20%] translate-x-1/2 -translate-y-1/2 w-[40%] max-w-[30vw]" />
            </div>
          </div>
          {links && (
            <div className="hidden lg:block flex-initial">
              <m.ul
                variants={parent}
                initial="out"
                whileInView="enter"
                className="flex items-center gap-4 will-change-transform"
              >
                {links.map((item) => {
                  return (
                    <m.li
                      key={item.id}
                      variants={fadeZoom}
                      className="will-change-transform"
                    >
                      <Link
                        href={item.url}
                        prefetch={false}
                        passHref
                        legacyBehavior
                      >
                        <Button as="a">{item.title}</Button>
                      </Link>
                    </m.li>
                  );
                })}
              </m.ul>
            </div>
          )}
        </div>
      </div>
    </section>
  );
}
