import Link from "next/link";
import React from "react";
import BrandingIcon from "components/SVG/BrandingIcon";
import Navbar from "components/Navbar";
import SocialsIconsMenu from "components/Menu/SocialsIconsMenu";
import BrandingLogo from "components/SVG/BrandingLogo";

export default function Header({ globals, home = false }) {
  return (
    <header className="fixed top-0 left-0 right-0 lg:right-auto bottom-auto lg:bottom-0 h-[80px] lg:h-auto lg:w-[105px] z-50">
      <div className="hidden lg:grid grid-rows-5 grid-flow-col h-full bg-light">
        <div className="row-span-3 flex flex-col">
          <div className="flex-initial">
            <Link href="/" prefetch={false} passHref legacyBehavior>
              <a className="block aspect-w-1 aspect-h-1" title="Accueil">
                <span className="flex items-center justify-center bg-dark">
                  <BrandingIcon className="w-[55px] text-primary hover:text-light transition-colors duration-300" />
                </span>
              </a>
            </Link>
          </div>
          <div className="flex-1">
            <Navbar globals={globals} />
          </div>
          <div className="flex-initial">
            <Link href="/contact" prefetch={false} passHref legacyBehavior>
              <a className="group block aspect-w-1 aspect-h-1">
                <span className="px-2 py-6 2xl:py-2 flex items-center justify-center bg-primary font-important tracking-wider font-semibold text-dark text-sm 2xl:text-base uppercase">
                  <span className="relative">
                    Contact
                    <span
                      className="absolute h-0.5 left-1/2 right-1/2 top-full bg-dark group-hover:inset-x-0 transition-all ease-in-sine duration-300"
                      aria-hidden="true"
                    />
                  </span>
                </span>
              </a>
            </Link>
          </div>
        </div>
        <div className="row-span-2 flex flex-col justify-center p-8 text-dark">
          {globals.menus && <SocialsIconsMenu menu={globals.menus.socials} />}
        </div>
      </div>
      <div className="relative flex items-center lg:hidden bg-dark h-full">
        <div className="container px-4 lg:px-12 mx-auto">
          <div>
            {home ? (
              <BrandingLogo className="w-[140px] text-light" />
            ) : (
              <Link href="/" prefetch={false}>
                <BrandingLogo className="w-[140px] text-light" />
              </Link>
            )}
          </div>
          <div className="absolute top-0 bottom-0 right-0">
            <Navbar globals={globals} />
          </div>
        </div>
      </div>
    </header>
  );
}
