import React from "react";
import Link from "next/link";
import ArrowUpRightIcon from "components/SVG/Icon/ArrowUpRightIcon";
import { useRouter } from "next/router";
import classNames from "lib/classNames";

export default function MainMenu({ menu, closeHandler = () => null }) {
  const { asPath } = useRouter();

  return (
    <ul className="flex flex-col justify-center h-full">
      {menu.map((item) => {
        const isCurrent = asPath === item.url;
        const isHomepage = asPath === "/" && isCurrent;
        const isActiveTrail = item.url !== "/" && asPath.includes(item.url);
        const isActive = isHomepage || isActiveTrail;
        const content = (
          <>
            <span
              className={classNames(
                "block text-4xl sm:text-6xl 2xl:text-7xl break-words sm:break-normal font-bold lowercase leading-normal sm:leading-normal 2xl:leading-normal group-hover:text-primary transition-colors duration-300 pr-12",
                isActive && "text-primary"
              )}
            >
              {item.title}
            </span>
            <span className="absolute right-0 top-1/2 text-primary">
              <span
                className="block h-4 w-4 rounded-full bg-primary transition-opacity group-hover:opacity-0 duration-300"
                aria-hidden="true"
              />
              <span className="absolute top-0 left-0 h-4 w-4 opacity-0 transition-opacity group-hover:opacity-100 duration-300 overflow-hidden">
                <ArrowUpRightIcon
                  className="h-4 w-4 animate-swipe-diagonal"
                  aria-hidden="true"
                />
              </span>
            </span>
          </>
        );

        return (
          <li key={item.title}>
            {isCurrent ? (
              <button
                type="button"
                className="group relative inline-block"
                onClick={closeHandler}
              >
                {content}
              </button>
            ) : (
              <Link href={item.url} prefetch={false} passHref legacyBehavior>
                <a className="group relative inline-block" title={item.title}>
                  {content}
                </a>
              </Link>
            )}
          </li>
        );
      })}
    </ul>
  );
}
