import React from "react";
import parse, { domToReact, Element } from "html-react-parser";
import { PrismAsync as SyntaxHighlighter } from "react-syntax-highlighter";
import { dracula } from "react-syntax-highlighter/dist/cjs/styles/prism";
import Image from "next/image";
import Link from "next/link";
import Button from "components/Button";

const options = {
  replace: (domNode) => {
    if (domNode instanceof Element && domNode.name === "img") {
      const { src, alt, width, height } = domNode.attribs;

      return (
        <Image
          src={`${process.env.NEXT_PUBLIC_DRUPAL_BASE_URL}/${src}`}
          width={`${width}px`}
          height={`${height}px`}
          alt={alt}
          className="object-contain"
          fill
        />
      );
    }

    if (domNode instanceof Element && domNode.name === "pre") {
      return (
        <>
          {domNode.children.map((subDomNode) => {
            if (subDomNode.name === "code") {
              const { class: className } = subDomNode.attribs;
              let lang = null;

              if (className && className.length > 0) {
                const classNames = className.split(" ");
                classNames.forEach((el) => {
                  if (el.startsWith("language-")) {
                    lang = el.split("-").pop();
                  }

                  return null;
                });
              }

              return (
                <SyntaxHighlighter language={lang} style={dracula}>
                  {domToReact(subDomNode.children)}
                </SyntaxHighlighter>
              );
            }

            return domToReact(subDomNode.children);
          })}
        </>
      );
    }

    if (domNode.name === "a") {
      const { href, class: className } = domNode.attribs;
      let [isButton, buttonType] = [false, "primary"];

      if (className && className.length > 0) {
        const classNames = className.split(" ");

        classNames.map((el) => {
          const [tag, theme] = el.split("--");

          if (tag === "button") {
            isButton = true;

            if (theme) {
              buttonType = theme;
            }
          }

          return el;
        });
      }

      return (
        <Link href={href} prefetch={false} passHref legacyBehavior>
          {isButton ? (
            <Button as="a" theme={buttonType}>
              {domToReact(domNode.children)}
            </Button>
          ) : (
            <a className={className} target="_blank" rel="noreferrer">
              {domToReact(domNode.children)}
            </a>
          )}
        </Link>
      );
    }

    return domNode;
  },
};

export default function Body({ value }) {
  return <>{parse(value, options)}</>;
}
