import React from "react";
import Link from "next/link";

export default function BasicMenu({ menu }) {
  return (
    <ul className="flex flex-col">
      {menu.map((item) => {
        return (
          <li key={item.title}>
            <Link href={item.url} prefetch={false} passHref legacyBehavior>
              <a title={item.title} className="font-medium hover:text-primary">
                {item.title}
              </a>
            </Link>
          </li>
        );
      })}
    </ul>
  );
}
