import React from "react";

export default function LinkedinIcon({ className }) {
  return (
    <svg
      width="11px"
      height="10px"
      viewBox="0 0 11 10"
      version="1.1"
      className={className}
      aria-hidden="true"
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-164.000000, -3720.000000)" fill="currentColor">
          <g transform="translate(0.000000, 3325.000000)">
            <g transform="translate(155.000000, 90.000000)">
              <g transform="translate(0.000000, 296.000000)">
                <path d="M16.8263381,11.8564911 C15.7484509,11.8564911 15.0608042,12.4209114 14.9026982,12.8386105 L14.8894314,12.8773182 L14.8894314,11.9154197 L12.709607,11.9154197 L12.7140406,12.058436 L12.7140406,12.058436 L12.7207521,12.8402547 L12.7207521,12.8402547 L12.721981,13.4433863 L12.721981,13.4433863 L12.709607,18.0444332 L14.8894314,18.0444332 L14.8891639,14.6587476 C14.8882516,14.4982029 14.8876752,14.3426343 14.9368443,14.2268741 C15.0866934,13.8582919 15.4086333,13.4758427 15.9875395,13.4758427 C16.7214551,13.4758427 17.0673351,14.0087566 17.0876236,14.7980262 L17.0885737,14.8727629 L17.0885737,18.0444332 L19.2888889,18.0444332 L19.2888889,14.6364599 C19.2888889,12.7380875 18.2054105,11.8564911 16.8263381,11.8564911 Z M11.5248643,11.915431 L9.58444604,11.915431 L9.58444604,18.0444444 L11.5248643,18.0444444 L11.5248643,11.915431 Z M10.5186571,9.02222222 C9.80219452,9.02222222 9.33333333,9.48092947 9.33333333,10.0863785 C9.33333333,10.6611144 9.75982523,11.1184421 10.4239484,11.1473491 L10.5051944,11.1488002 C11.2345336,11.1488002 11.6887624,10.6785378 11.6887624,10.0846439 C11.6747137,9.47977463 11.2345336,9.02222222 10.5186571,9.02222222 Z" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
