import React from "react";
import Link from "next/link";

export default function SocialsMenu({ menu }) {
  return (
    <ul className="flex flex-col lg:flex-row flex-wrap gap-1 lg:gap-4">
      {menu.map((item, index) => {
        return (
          <li key={item.title} className="flex gap-4 text-lg">
            {index > 0 && <span className="hidden lg:block">|</span>}
            <Link href={item.url} prefetch={false} passHref legacyBehavior>
              <a
                target="blank"
                rel="noreferrer"
                title={item.title}
                className="font-medium hover:text-primary"
              >
                {item.title}
              </a>
            </Link>
          </li>
        );
      })}
    </ul>
  );
}
