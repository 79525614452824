import React from "react";
import Head from "next/head";
import { useRouter } from "next/router";

export default function Meta({ title, description, image, schema = null }) {
  const { asPath } = useRouter();

  const canonicalUrl = `${process.env.NEXT_PUBLIC_APP_BASE_URL}${
    asPath === "/" ? "" : asPath
  }`.split("?")[0];
  const placeholderUrl = `${process.env.NEXT_PUBLIC_APP_BASE_URL}/images/placeholder.jpg`;
  const siteTitle = title
    ? `${title.replace(/(<([^>]+)>)/gi, "")}`
    : "ISICS, agence de développement web et mobile depuis 2004";
  const siteDescription = description
    ? description.replace(/(<([^>]+)>)/gi, "")
    : "Agence web spécialisée dans la création de sites web vitrines et e-commerce sur-mesure, le développement d’applications, le marketing digital et le design UI/UX.";

  return (
    <Head>
      <title>{siteTitle}</title>
      <meta
        key="viewport"
        name="viewport"
        content="width=device-width, initial-scale=1"
      />
      <meta key="title" name="title" content={siteTitle} />
      <meta key="description" name="description" content={siteDescription} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={canonicalUrl} />
      <meta key="og:title" property="og:title" content={siteTitle} />
      <meta
        key="og:description"
        property="og:description"
        content={siteDescription}
      />
      <meta property="og:image" content={image || placeholderUrl} />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="627" />
      <meta name="msapplication-TileColor" content="#A4FF00" />
      <meta name="theme-color" content="#212327" />
      <link rel="canonical" href={canonicalUrl} />
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/favicons/apple-touch-icon.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/favicons/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/favicons/favicon-16x16.png"
      />
      <link rel="manifest" href="/manifest.json" />
      {schema && (
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: JSON.stringify(schema) }}
        />
      )}
    </Head>
  );
}
