import React, { forwardRef } from "react";
import classNames from "lib/classNames";
import ArrowUpRightIcon from "components/SVG/Icon/ArrowUpRightIcon";

const Button = forwardRef(
  (
    { children, theme = "primary", as = "button", size = "default", ...props },
    ref
  ) => {
    const Tag = as;

    const themes = {
      primary: {
        button: "bg-transparent border-primary text-light hover:text-dark",
        bullet: "bg-primary",
      },
      "hover-dark": {
        button: "bg-transparent border-dark text-dark hover:text-primary",
        bullet: "bg-dark",
      },
      "hover-light": {
        button:
          "bg-transparent border-dark text-dark hover:border-light hover:text-dark",
        bullet: "bg-dark group-hover:bg-light",
      },
      dark: {
        button:
          "bg-dark border-dark text-light hover:text-dark hover:border-primary",
        bullet: "bg-primary",
      },
    };

    const sizes = {
      default: {
        bullet: "lg:h-3 lg:w-3 lg:right-9",
        font: "lg:text-md 2xl:text-lg lg:font-semibold",
        gap: "lg:pr-8",
        icon: "lg:right-9",
        wrapper: "lg:py-6 lg:px-9",
      },
      small: {
        bullet: "",
        font: "",
        gap: "",
        icon: "",
        wrapper: "",
      },
    };

    return (
      <Tag
        ref={ref}
        className={classNames(
          "group relative inline-flex items-center gap-2 no-underline border-2 rounded-full text-base font-medium font-important tracking-wider whitespace-nowrap transition-colors duration-300",
          themes[theme].button,
          sizes[size].font
        )}
        {...props}
      >
        <span
          className={classNames(
            "relative py-4 px-7 leading-none",
            sizes[size].wrapper
          )}
        >
          <span
            className={classNames(
              "absolute top-1/2 right-7 -translate-y-1/2 block h-2 w-2 rounded-full transition-all duration-300 group-hover:h-[101%] group-hover:w-[101%] group-hover:-right-px",
              themes[theme].bullet,
              sizes[size].bullet
            )}
            aria-hidden="true"
          />
          <span
            className={classNames(
              "block absolute top-1/2 right-7 h-3 w-3 -translate-y-1/2 opacity-0 group-hover:opacity-100 transition-opacity duration-300 overflow-hidden",
              sizes[size].icon
            )}
          >
            <ArrowUpRightIcon className="h-3 w-3 animate-swipe-diagonal" />
          </span>
          <span className={classNames("relative pr-6", sizes[size].gap)}>
            {children}
          </span>
        </span>
      </Tag>
    );
  }
);

Button.displayName = "Button";
export default Button;
