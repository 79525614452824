import React from "react";

export default function BehanceIcon({ className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 20"
      className={className}
      aria-hidden="true"
    >
      <g fill="currentColor" fillRule="evenodd">
        <path d="M10.704 15.555c-.474.227-1.141.34-1.996.34H3.926v-5.13h4.848c.845.007 1.502.117 1.972.324.837.375 1.253 1.061 1.253 2.065 0 1.183-.43 1.98-1.295 2.401ZM3.926 3.34h4.286c.943 0 1.72.1 2.329.3.705.29 1.058.88 1.058 1.778 0 .807-.27 1.37-.801 1.688-.536.318-1.231.477-2.084.477H3.926V3.34Zm9.184 5.572c.662-.329 1.162-.696 1.504-1.092.61-.714.91-1.66.91-2.833 0-1.142-.3-2.118-.902-2.936C13.618.718 11.92.035 9.517 0H0v19.23h8.874c1 0 1.926-.084 2.782-.258.857-.175 1.598-.496 2.225-.966a5.353 5.353 0 0 0 1.394-1.512c.586-.903.879-1.926.879-3.066 0-1.104-.257-2.045-.767-2.818-.516-.773-1.273-1.339-2.277-1.698ZM20 3.077h8.462V.769H20zM20.897 10.816c.141-.944.484-1.692 1.028-2.245.544-.55 1.314-.828 2.299-.83.909 0 1.669.259 2.287.78.61.525.955 1.287 1.026 2.295h-6.64Zm10.51-.377c-.178-1.136-.57-2.135-1.178-2.998-.667-.977-1.513-1.693-2.543-2.146-1.026-.454-2.18-.682-3.466-.68-2.158 0-3.911.674-5.268 2.011-1.352 1.342-2.029 3.27-2.029 5.784 0 2.681.748 4.618 2.251 5.806C20.671 19.406 22.4 20 24.364 20c2.375 0 4.223-.706 5.543-2.114.844-.889 1.322-1.763 1.427-2.622H27.4c-.228.424-.492.756-.794.997-.548.443-1.262.664-2.136.664-.832 0-1.538-.183-2.126-.547-.971-.583-1.48-1.605-1.544-3.059h10.735c.016-1.253-.024-2.216-.129-2.88Z" />
      </g>
    </svg>
  );
}
