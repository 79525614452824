import React from "react";
import { m } from "framer-motion";

export default function SigilIllustration({
  className,
  color = "currentColor",
  accentColor = "#A4FF00",
}) {
  const grid = {
    hidden: { opacity: 0 },
    show: {
      opacity: 0.3,
      transition: {
        staggerChildren: 0.1,
      },
    },
  };

  const asterisk = {
    hidden: { opacity: 0 },
    show: {
      opacity: [0, 1, 1, 0],
      transition: {
        duration: 4,
        delay: 4,
        delayChildren: 4,
        staggerChildren: 0.1,
      },
    },
  };

  const final = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        delay: 7,
        delayChildren: 7,
        staggerChildren: 1,
      },
    },
  };

  const line = {
    hidden: { pathLength: 0 },
    show: {
      pathLength: 1,
      transition: {
        duration: 1,
      },
    },
  };

  const letter = {
    hidden: { opacity: 0 },
    show: {
      opacity: [0, 1, 1, 1, 1, 0],
      transition: {
        duration: 3,
        delay: 2,
      },
    },
  };

  const arrow = {
    hidden: { opacity: 0 },
    show: {
      opacity: [0, 1, 1, 1, 0],
      transition: {
        duration: 5,
        delay: 3,
      },
    },
  };

  const appear = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        duration: 1,
      },
    },
  };

  const toggle = {
    hidden: { opacity: 0 },
    show: {
      opacity: [0, 1, 1, 0],
      transition: {
        duration: 2,
      },
    },
  };

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      xmlSpace="preserve"
      viewBox="0 0 1457.19 1500.59"
      className={className}
      aria-hidden="true"
    >
      <switch>
        <g>
          <m.g variants={grid} initial="hidden" animate="show">
            <m.path
              fill="none"
              stroke={color}
              strokeWidth="4"
              strokeMiterlimit="10"
              d="M250.57 396.71v924.05"
              variants={line}
            />
            <m.path
              fill="none"
              stroke={color}
              strokeWidth="4"
              strokeMiterlimit="10"
              d="M565.79 396.71v924.05"
              variants={line}
            />
            <m.path
              fill="none"
              stroke={color}
              strokeWidth="4"
              strokeMiterlimit="10"
              d="M881 396.71v924.05"
              variants={line}
            />
            <m.path
              fill="none"
              stroke={color}
              strokeWidth="4"
              strokeMiterlimit="10"
              d="M408.18 396.71v924.05"
              variants={line}
            />
            <m.path
              fill="none"
              stroke={color}
              strokeWidth="4"
              strokeMiterlimit="10"
              d="M723.4 396.71v924.05"
              variants={line}
            />
            <m.path
              fill="none"
              stroke={color}
              strokeWidth="4"
              strokeMiterlimit="10"
              d="M103.76 1173.95h924.05"
              variants={line}
            />
            <m.path
              fill="none"
              stroke={color}
              strokeWidth="4"
              strokeMiterlimit="10"
              d="M103.76 858.73h924.05"
              variants={line}
            />
            <m.path
              fill="none"
              stroke={color}
              strokeWidth="4"
              strokeMiterlimit="10"
              d="M103.76 543.52h924.05"
              variants={line}
            />
            <m.path
              fill="none"
              stroke={color}
              strokeWidth="4"
              strokeMiterlimit="10"
              d="M103.76 1016.34h924.05"
              variants={line}
            />
            <m.path
              fill="none"
              stroke={color}
              strokeWidth="4"
              strokeMiterlimit="10"
              d="M103.76 701.12h924.05"
              variants={line}
            />
          </m.g>

          <m.g variants={letter} initial="hidden" animate="show">
            <path
              fill={accentColor}
              d="M880.82 1173.95H723.4V543.52h157.42v630.43z"
            />
          </m.g>

          <m.g variants={arrow} initial="hidden" animate="show">
            <path
              fill={accentColor}
              d="M 881.2,618 V 543.4 H 723.8 250.8 V 700.8 L 616.9,701 328.6,984.8 435.5,1100.5 723.7,812.3 v 361.5 h 157.4 v 0 h 0.2 v -167.5 c 0,-0.5 -0.1,-1 -0.2,-1.6 V 619.6 c 0,-0.5 0.1,-1.1 0.1,-1.6 z"
            />
          </m.g>

          <m.g variants={asterisk} initial="hidden" animate="show">
            <m.path
              fill={color}
              d="M880.6 589.62 769.29 700.94 323.51 255.16l111.31-111.31L880.6 589.62z"
              variants={toggle}
            />
            <m.path
              fill={color}
              d="M723.18 70.51H880.6v630.43H723.18V70.51z"
              variants={toggle}
            />
            <m.path
              fill={color}
              d="M834.31 700.94 723 589.62l445.78-445.78 111.31 111.31-445.78 445.79z"
              variants={toggle}
            />
            <m.path
              fill={color}
              d="M1353.43 543.7v157.42H723V543.7h630.43z"
              variants={toggle}
            />
            <m.path
              fill={color}
              d="m723 654.83 111.31-111.31 445.78 445.78-111.31 111.31L723 654.83z"
              variants={toggle}
            />

            <path
              fill={accentColor}
              d="M 881.2,618 V 543.4 H 723.8 250.8 V 700.8 L 616.9,701 328.6,984.8 435.5,1100.5 723.7,812.3 v 361.5 h 157.4 v 0 h 0.2 v -167.5 c 0,-0.5 -0.1,-1 -0.2,-1.6 V 619.6 c 0,-0.5 0.1,-1.1 0.1,-1.6 z"
            />
          </m.g>

          <m.g variants={final} initial="hidden" animate="show">
            <m.g variants={toggle}>
              <path
                fill={accentColor}
                d="M 881.2,618 V 543.4 H 723.8 250.8 V 700.8 L 616.9,701 328.6,984.8 435.5,1100.5 723.7,812.3 v 361.5 h 157.4 v 0 h 0.2 v -167.5 c 0,-0.5 -0.1,-1 -0.2,-1.6 V 619.6 c 0,-0.5 0.1,-1.1 0.1,-1.6 z"
              />
              <path
                fill={color}
                d="M1010.62 1179.3v-166.25c0-56.01-20.84-110.85-57.17-150.47l-46-50.15 46-50.17c36.87-40.19 57.17-93.63 57.17-150.46V445.55c0-18.66 6.95-36.95 19.05-50.14l92.02-100.3-92.01-100.33-92.02 100.32c-36.33 39.61-57.18 94.44-57.18 150.46V611.8c0 18.94-6.77 36.75-19.06 50.15L723.4 812.44 861.44 962.9c12.1 13.2 19.05 31.49 19.05 50.15v166.25c0 56.83 20.31 110.26 57.16 150.46l92.02 100.33 92.03-100.3-92.03-100.33c-12.28-13.41-19.05-31.23-19.05-50.16z"
              />
            </m.g>

            <m.path
              fill={accentColor}
              d="m723.58 812.22 138.63-151.14c10.72-11.69 17.26-26.73 18.79-42.96v-74.6H250.57v157.42l366.12.19-288.36 283.75 106.89 115.73 288.36-288.36v361.69H881v-167.51c-1.55-16.15-8.21-31.56-18.78-43.1L723.58 812.22z"
              variants={appear}
            />
          </m.g>
        </g>
      </switch>
    </svg>
  );
}
