import React from "react";
import Body from "components/Body";
import BasicMenu from "components/Menu/BasicMenu";
import SocialsMenu from "components/Menu/SocialsMenu";
import BrandingLogo from "components/SVG/BrandingLogo";
import Link from "next/link";

export default function Footer({ globals, lightFooter = false }) {
  const currentYear = new Date().getFullYear();
  return (
    <footer className="relative py-16 2xl:pt-28 lg:pl-[105px] bg-dark text-white">
      <div className="container px-4 lg:px-12 mx-auto">
        {!lightFooter && (
          <>
            <div className="text-center lg:text-left mb-12 lg:mb-20">
              <BrandingLogo className="inline-block w-[200px]" showBaseline />
            </div>

            <div className="grid grid-cols-1 lg:grid-cols-12 gap-8 lg:gap-12 mb-12 lg:mb-20">
              {globals?.blocks?.contact && (
                <div className="hidden md:block lg:col-span-4">
                  <h2 className="sr-only">
                    {globals.blocks.contact.field_title}
                  </h2>

                  {globals.blocks.contact.body && (
                    <div className="prose prose-light">
                      <Body value={globals.blocks.contact.body.processed} />
                    </div>
                  )}
                </div>
              )}
              {globals?.blocks?.coordinates && (
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-1 gap-5 lg:gap-0 lg:col-span-5">
                  <div>
                    <h2 className="sr-only">
                      {globals.blocks.coordinates.field_title}
                    </h2>

                    {globals.blocks.coordinates.body && (
                      <div className="prose prose-light">
                        <Body
                          value={globals.blocks.coordinates.body.processed}
                        />
                      </div>
                    )}
                  </div>

                  <div>
                    <h2 className="text-lg text-primary font-medium font-important tracking-wider mb-2">
                      Suivez-nous
                    </h2>
                    <SocialsMenu menu={globals.menus.socials} />
                  </div>
                </div>
              )}
              <div className="hidden lg:block lg:col-span-3">
                <h2 className="text-lg text-primary font-medium font-important tracking-wider mb-2">
                  À propos
                </h2>
                <div className="text-lg 2xl:text-xl">
                  <BasicMenu menu={globals.menus.secondary} />
                </div>

                <h2 className="text-lg text-primary font-medium font-important tracking-wider mt-8 mb-2">
                  Notre expertise
                </h2>
                <div className="text-lg 2xl:text-xl">
                  <BasicMenu menu={globals.menus.expertise} />
                </div>
              </div>
            </div>
          </>
        )}
        <div className="flex flex-col md:flex-row flex-wrap items-center justify-center gap-2 text-center 2xl:text-lg font-medium mb-4">
          <span>© ISICS 2004 - {currentYear}, tous droits réservés</span>
          <span className="hidden md:block">-</span>
          <Link href="/mentions-legales" passHref legacyBehavior>
            <a className="hover:text-primary transition-colors duration-300">
              Mentions légales
            </a>
          </Link>
          <span className="hidden md:block">-</span>
          <Link
            href="/politique-de-confidentialite-des-donnees"
            passHref
            legacyBehavior
          >
            <a className="hover:text-primary transition-colors duration-300">
              Politique de confidentialité
            </a>
          </Link>
        </div>
        {/* <WebsiteCarbonBadge url="" dark /> */}
      </div>
    </footer>
  );
}
