import React from "react";
import PreviewAlert from "components/PreviewAlert";
import Header from "components/Header";
import HomeSection from "components/Section/HomeSection";
import Footer from "components/Footer";
import { m } from "framer-motion";
import { main } from "lib/variants";

export default function Layout({
  children,
  globals,
  home = false,
  lightFooter = false,
}) {
  return (
    <>
      <PreviewAlert />
      <Header globals={globals} home={home} />
      <m.main
        variants={main}
        initial="out"
        animate="enter"
        className="relative pt-[80px] lg:pt-0 lg:pl-[105px] will-change-transform"
      >
        {home && <HomeSection links={globals.menus.expertise} />}
        {children}
      </m.main>
      <Footer globals={globals} lightFooter={lightFooter} />
    </>
  );
}
