import React from "react";
import Link from "next/link";
import getSocialFromUrl from "lib/getSocialFromUrl";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/solid";
import FacebookIcon from "components/SVG/Icon/FacebookIcon";
import InstagramIcon from "components/SVG/Icon/InstagramIcon";
import LinkedinIcon from "components/SVG/Icon/LinkedinIcon";
import TwitterIcon from "components/SVG/Icon/TwitterIcon";
import BehanceIcon from "components/SVG/Icon/BehanceIcon";

export default function SocialsIconsMenu({ menu }) {
  const components = {
    behance: BehanceIcon,
    facebook: FacebookIcon,
    instagram: InstagramIcon,
    linkedin: LinkedinIcon,
    twitter: TwitterIcon,
    other: ArrowTopRightOnSquareIcon,
  };

  return (
    <ul className="flex flex-row lg:flex-col gap-8">
      {menu.map((item) => {
        const social = getSocialFromUrl(item.url);
        const IconComponent = components[social];

        return (
          <li key={item.title}>
            <Link href={item.url} prefetch={false} passHref legacyBehavior>
              <a
                target="blank"
                rel="noreferrer"
                className="group relative flex items-center justify-center text-dark hover:text-white transition-colors duration-300"
                title={item.title}
              >
                <span
                  className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-10 h-10 bg-dark rounded-full scale-0 group-hover:scale-100 transition-transform ease-in-sine duration-300"
                  aria-hidden="true"
                />
                <IconComponent className="relative w-5 h-5" />
                <span className="sr-only">{item.title}</span>
              </a>
            </Link>
          </li>
        );
      })}
    </ul>
  );
}
