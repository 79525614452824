import React, { useState } from "react";
import { Dialog } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/solid";
import MenuIcon from "components/SVG/Icon/MenuIcon";
import BrandingLogo from "components/SVG/BrandingLogo";
import MainMenu from "components/Menu/MainMenu";
import Link from "next/link";
import Button from "components/Button";
import SocialsIconsMenu from "components/Menu/SocialsIconsMenu";
import MenuIllustration from "components/SVG/Illustration/MenuIllustration";
import { AnimatePresence, m } from "framer-motion";
import { slideOut } from "lib/variants";

export default function Navbar({ globals }) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <nav className="flex h-full">
      <button
        type="button"
        className="group flex flex-col items-center justify-center w-full h-full px-8 lg:py-8 lg:px-2 bg-primary lg:bg-dark"
        onClick={() => setIsOpen((open) => !open)}
      >
        {isOpen ? (
          <XMarkIcon className="w-12 h-12 2xl:w-16 2xl:h-16 text-dark lg:text-primary" />
        ) : (
          <MenuIcon className="w-12 h-6 2xl:w-16 2xl:h-16 text-dark lg:text-primary" />
        )}
        <span className="sr-only lg:not-sr-only font-important tracking-wider uppercase font-semibold text-sm 2xl:text-base text-light group-hover:text-primary transition-colors duration-300">
          {isOpen ? "Fermer" : "Menu"}
        </span>
      </button>
      <AnimatePresence>
        {isOpen && (
          <Dialog
            as="div"
            className="fixed top-[80px] lg:top-0 left-0 lg:left-[105px] right-0 inset-y-0 z-40"
            onClose={() => setIsOpen(false)}
            open={isOpen}
            static
          >
            <m.div
              variants={slideOut}
              initial="out"
              animate="enter"
              exit="exit"
              className="absolute inset-0 bg-dark text-light h-full overflow-auto will-change-transform "
            >
              <Dialog.Panel className="relative container px-4 lg:px-12 py-12 mx-auto h-full">
                <div className="flex flex-col h-full">
                  <div className="hidden lg:block flex-initial">
                    <Link href="/" prefetch={false} passHref legacyBehavior>
                      <a
                        className="inline-block"
                        onClick={() => setIsOpen((o) => !o)}
                      >
                        <BrandingLogo className="w-[200px]" showBaseline />
                      </a>
                    </Link>
                  </div>
                  <div className="flex-1 flex flex-col justify-center gap-12 lg:py-12 mb-12 lg:mb-0">
                    <div className="flex-1 grid grid-cols-1 xl:grid-cols-5 gap-8 h-full">
                      <div className="xl:col-span-3">
                        {globals?.menus?.main && (
                          <MainMenu
                            menu={globals.menus.main}
                            closeHandler={() => setIsOpen(false)}
                          />
                        )}
                      </div>
                      <div className="hidden xl:block">
                        <MenuIllustration className="absolute top-1/2 right-1/4 translate-x-1/2 -translate-y-1/2 w-full max-w-[30vw]" />
                      </div>
                    </div>
                  </div>
                  <div className="flex-initial">
                    <Link
                      href="/contact"
                      prefetch={false}
                      passHref
                      legacyBehavior
                    >
                      <Button as="a">Contactez-nous</Button>
                    </Link>
                  </div>
                  <div className="block lg:hidden flex-initial text-light ">
                    <SocialsIconsMenu menu={globals.menus.socials} />
                  </div>
                </div>
              </Dialog.Panel>
            </m.div>
          </Dialog>
        )}
      </AnimatePresence>
    </nav>
  );
}
